<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item label="版本">
        <el-input style="width: 200px" clearable v-model.trim="searchData.appVersion" size="mini" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="tabs">
      <div :class="['item', searchData.appType === 1 ? 'active' : '']" @click="handleClickTab(1)">
        <el-badge>
          <span class="tab-name">安卓端</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.appType === 2 ? 'active' : '']" @click="handleClickTab(2)">
        <el-badge>
          <span class="tab-name">ios端</span>
        </el-badge>
      </div>
    </div>
    <el-button style="margin-bottom: 10px" icon="el-icon-plus" size="mini" plain type="primary" @click="handleAdd">新增</el-button>
    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="id" label="设备类型" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.appType == 1" type="primary" size="mini">安卓端</el-tag>
          <el-tag v-if="row.appType == 2" type="success" size="mini">ios端</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="appVersion" label="版本" min-width="120"> </el-table-column>
      <el-table-column prop="downloadUrl" label="下载地址" min-width="120"> </el-table-column>
      <el-table-column prop="upgradeIntro" label="更新说明" min-width="160"> </el-table-column>
      <el-table-column prop="upgradeIntro" label="是否强制更新" min-width="110">
        <template slot-scope="{ row }">
          <el-tag v-if="row.isForceUpdate == 1" type="primary" size="mini">是</el-tag>
          <el-tag v-if="row.isForceUpdate == 0" type="danger" size="mini">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 新增编辑弹窗 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="">
      <el-form :model="formData" ref="form" label-width="120px" size="mini">
        <el-form-item label="类型：" prop="appType" :rules="rules">
          <el-radio-group v-model="formData.appType">
            <el-radio :label="1">安卓端</el-radio>
            <el-radio :label="2">ios端</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="版本：" prop="appVersion" :rules="rules">
          <el-input style="width: 300px" placeholder="请输入" clearable v-model="formData.appVersion"></el-input>
        </el-form-item>
        <el-form-item label="下载地址：" prop="downloadUrl" :rules="rules">
          <el-input style="width: 300px" placeholder="请输入" clearable v-model="formData.downloadUrl"></el-input>
        </el-form-item>
        <el-form-item label="更新说明：" prop="upgradeIntro" :rules="rules">
          <el-input style="width: 300px" type="textarea" placeholder="请输入" clearable v-model="formData.upgradeIntro"></el-input>
        </el-form-item>
        <el-form-item label="是否强制更新：" :rules="rules">
          <el-radio-group v-model="formData.isForceUpdate">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="show_update = false">取消</el-button>
        <el-button size="small" type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, delAPI, getDetailAPI, addAPI, editAPI } from './api'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      list: [],
      total: 0,
      searchData: {
        page: 1,
        pagesize: 10,
        appType: 1,
        appVersion: ''
      },
      formData: {
        appType: 1, //1安卓  2 ios
        appVersion: '',
        downloadUrl: '',
        upgradeIntro: '',
        isForceUpdate: 0
      }
    }
  },

  watch: {},
  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const res = await getListAPI(this.searchData)
      this.list = res.records
      this.total = res.totalRow
    },
    handleAdd() {
      this.formData = {
        appType: 1,
        appVersion: '',
        downloadUrl: '',
        upgradeIntro: '',
        isForceUpdate: 0
      }
      this.show_update = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.show_update = false
              this.getList()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.show_update = false
              this.getList()
            })
          }
        }
      })
    },
    handleClickTab(i) {
      if (this.searchData.appType == i) return
      this.searchData.appType = i
      this.getList()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
